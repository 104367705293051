<template>
    <div class="video-player">
      <video ref="video" @play="onPlay" @pause="onPause" controls>
        <source :src="videoSrc" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoPlayer',
    props: {
      src: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isPlaying: false,
        videoSrc: ''
      };
    },
    mounted() {
      this.videoSrc = this.VUE_APP_VIDEO_URL + '/' + this.src
    },
    methods: {
      togglePlay() {
        const video = this.$refs.video;
        if (this.isPlaying) {
          video.pause();
        } else {
          video.play();
        }
      },
      onPlay() {
        this.isPlaying = true;
      },
      onPause() {
        this.isPlaying = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .video-player {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  video {
    width: 100%;
    max-width: 600px;
  }
  button {
    margin-top: 10px;
  }
  </style>
  