<template>
    <div class="minder">
      <v-dialog
        v-model="minderVisible"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
            height="50"
          >
            <v-toolbar-title>编辑</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="minderVisible = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Minder
            v-if="minderVisible"
            ref="myMinder"
            @saveData="saveData"
            @exportData="changeSave"
            :importData="minderData.root"
            :template="minderData.template"
            :theme="minderData.theme"
          />
        </v-card>
      </v-dialog>
      </div>
    </template>
    <script>
    import Vue from 'vue'
    import MindEdiMindertor from 'vue-minder-editor-antv'
    import { randomString } from '@/utils/util'
    import 'vue-minder-editor-antv/lib/vue-minder-editor-antv.css'
    import { ACCESS_TOKEN } from '@/store/mutation-types'
    import { httpAction } from '@/api/manage'
    export default {
      name: 'MindeEditor',
      components: {
        Minder: MindEdiMindertor.Minder
      },
      data() {
        return {
          minderVisible: false,
          fullscreen: true,
          switchFullscreen: true,
          messageId: randomString('16'),
          loading: false,
          height: '60%',
          url: {
            edit: '/mind/ysMind/edit',
            add: '/mind/ysMind/add'
          },
          ajaxState: true
        }
      },
      props: {
        minderData: {
          type: Object,
          default: () => {
            return {
              root: {},
              template: 'structure',
              theme: 'fresh-blue',
              timeout: '',
              mindId: ''
            }
          }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
          type: String,
          default: ''
        },
        businessId: {
          type: String,
          default: ''
        },
        tableName: {
          type: String,
          default: ''
        },
        fieldName: {
          type: String,
          default: ''
        },
        category: {
          type: String,
          default: ''
        }
      },
      computed: {
      },
      methods: {
        showMind() {
          this.loading = true
          this.minderVisible = true
          this.$nextTick(() => {
            this.mindId = this.id
            
          })
        },
        closeMind() {
          this.$emit('ok')
          this.minderVisible = false
        },
        saveData(data) {
          this.submitForm(data, 'save')
        },
        changeSave(data) {
            // console.log('changeSave')
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.submitForm(data, 'changeSave')
          }, 1000)
        },
        submitForm(minddata, type) {
          if(this.disabled){
            this.$message.warning('没有编辑权限！')
            return
          }
          this.confirmLoading = true
          let httpurl = ''
          let method = ''
          const data = {}
          if (!this.mindId) {
            httpurl += this.url.add
            method = 'post'
          } else {
            httpurl += this.url.edit
            method = 'put'
          }
          data.id = this.mindId
          data.name = minddata.root.data.text
          data.minddata = JSON.stringify(minddata)
          data.businessId = this.businessId
          data.tableName = this.tableName
          data.fieldName = this.fieldName
          data.category = this.category
        //   console.log(data)
          if(this.ajaxState === false)return;
          this.ajaxState = false;
          httpAction(httpurl, data, method).then((res) => {
            if (res.success) {
              if (type !== 'changeSave') {
                this.$message.success(res.message)
                this.closeMind()
              } else {
                this.$message.success('保存成功')
              }
              this.mindId = res.result.id
            } else {
              this.$message.error(res.message)
            }
            this.ajaxState = true
          }).finally(() => {
            this.confirmLoading = false
          })
        }
      },
      mounted() {
        // console.log(this.minderData)
        // console.log(this.businessId, this.tableName)
      }
    }
    </script>
    <style scoped>
    .main{
        height: 100%;
    }
    .ant-layout-content{
        position: relative;
    }
    .minder-editor-container{
      margin-top: 50px;
    }
    </style>
    